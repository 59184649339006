<template>
  <el-config-provider :locale="locale">
      <router-view />
    </el-config-provider>
</template>


<script >

import { getCurrentInstance, onMounted, reactive } from 'vue'
import { ElConfigProvider } from 'element-plus'
import { useStore } from 'vuex';
import zhCn from "element-plus/lib/locale/lang/zh-cn"
 let  locale = zhCn
export default {
  name: 'App',

  components: {
    ElConfigProvider,
  },
  setup() {
    const store = useStore()
    const { proxy } = getCurrentInstance()
    const configInfo = reactive({ data: {} })
    // 获取系统配置信息
    const getSystemConfigInfo = () => {
      proxy.$get("v3/system/front/setting/getSettings", {
        names: "main_site_logo,main_user_center_logo,main_user_logon_bg,main_user_register_bg,pc_home_bottom_adv,main_user_forget_password_bg,basic_site_name,basic_site_icp,basic_site_copyright,basic_site_technical_support,platform_customer_service_name,platform_customer_service_logo"
      }).then(res => {
        if (res.state == 200) {
          configInfo.data = {
            main_site_logo: res.data[0],
            main_user_center_logo: res.data[1],
            main_user_logon_bg: res.data[2],
            main_user_register_bg: res.data[3],
            pc_home_bottom_adv: res.data[4],
            main_user_forget_password_bg: res.data[5],
            basic_site_name: res.data[6],
            basic_site_icp: res.data[7],
            basic_site_copyright: res.data[8],
            basic_site_technical_support: res.data[9],
            platform_customer_service_name: res.data[10],
            platform_customer_service_logo: res.data[11]
          }
          store.commit('updateConfigInfo', configInfo.data);
        }
      })
    };


    onMounted(() => {
      getSystemConfigInfo()
    })
    return{
      locale
    }
  }

}
</script>

<style lang="scss">
  @import "./style/reset.scss";
  @import "./style/base.scss";
  //放大镜位置
  .mouse-cover-canvas {
    position: absolute;
    top: 173px !important;
    left: 740px !important;
  }
</style>
